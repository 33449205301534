@font-face {
  font-display: swap;
  font-family: 'R-regular';
  src:url('./Raleway-Regular.ttf') format('truetype');
}
@font-face {
  font-display: swap;
  font-family: 'R-semiBold';
  src:url('./Raleway-SemiBold.ttf') format('truetype');
}
