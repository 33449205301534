@import '/src/styles/base/mixin.scss';
@import '/src/styles/fonts/fonts.scss';
@import '/src/styles/base/variables.scss';

.nameLogo{
    display: flex;
    flex-direction: row;
}

.nav {
    height: 50px;
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid #6e6c6c;
    background-color: #1E201E;
}

.logo {
    border-left: 2px solid #6e6c6c;
    padding-left: 5px;
    border-image: linear-gradient(to bottom, transparent, transparent 25%, #6e6c6c 45%, transparent 70%);
    border-image-slice: 1;
}

.nav_content{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0px;
    height: 100%;
    color: white;
    div {
      align-items: center;
      align-self: center;
    }
}

.nav_centreButtons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    div, img{
    padding: 5px;
    margin: 0px 10px 0px 0px;
    &:hover{
        cursor: pointer;
    }
    }
    img{
        margin: 5px;
        height: 35px;
        width: 35px;
        &:hover{
            cursor: pointer;
        }
    }
    .nav_slogan{
      font-family:  Bradley hand, cursive;
      font-weight: bold;
      font-size: 15px;
      padding-right: 8px;
    }
}

.nav_logo {
  height: 100px;
  width: 70px;
  padding: 0px 0px 0px 4px;
}


.say_hello{
    animation: shake 5s infinite forwards;
    box-shadow: 0 4px 8px 0 rgba(253, 253, 253, 0.2), 0 6px 20px 0 rgba(44, 42, 42, 0.19);
}
