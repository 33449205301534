/* Containers */

$xs_screen: 320px;
$sm_screen: 620px;
$md_screen: 960px;
$lg_screen: 1100px;
$xl_screen: 2000px;

/* Breakpoints */

$xs_Mobile: $xs_screen;
$sm_Mobile: $sm_screen;
$md_Tablet: $md_screen;
$lg_Desktop: $lg_screen;
$xl_Desktop: $xl_screen;

$content_width: 800px;


/* Colors */

$background: #1E1F1E;
$whiteText: #F2F0F0;
$ashBorder: rgb(153, 146, 146);

/* Animation */

@keyframes rotate {
    0%,
    4% {
      transform: rotate(1deg);
    }
    1%,
    3%,
    5%,
    7% {
      transform: rotate(-1deg);
    }
    2%,
    6% {
      transform: rotate(2deg);
    }
    8% {
      transform: rotate(0deg);
    }
  }
  
  @keyframes textBlink {
    0%,
    100% {
      transform: scale(0.98);
      opacity: 0.9;
    }
    50% {
      transform: scale(1);
      opacity: 1;
    }
  }

  @keyframes shake {
    80% { transform: translate(1px, 1px) rotate(0deg); }
    82% { transform: translate(-1px, -2px) rotate(-1deg); }
    84% { transform: translate(-3px, 0px) rotate(1deg); }
    86% { transform: translate(3px, 2px) rotate(0deg); }
    88% { transform: translate(1px, -1px) rotate(1deg); }
    90% { transform: translate(-1px, 2px) rotate(-1deg); }
    92% { transform: translate(-3px, 1px) rotate(0deg); }
    94% { transform: translate(3px, 1px) rotate(-1deg); }
    96% { transform: translate(-1px, -1px) rotate(1deg); }
    98% { transform: translate(1px, 2px) rotate(0deg); }
    100% { transform: translate(1px, -2px) rotate(-1deg); }
  }
  
  @keyframes fadeInFadeOut {
    0% {
      opacity: 0;
      display: block;
    }
    10%,
    99% {
      opacity: 1;
      transform: translateY(75px) scale(0.98);
    }
    100% {
      opacity: 0;
      display: none;
      animation: none;
    }
  }
  